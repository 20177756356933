@tailwind base;
@tailwind components;
@tailwind utilities;

@import "tippy.js/dist/tippy.css";
@import "react-toastify/dist/ReactToastify.css";
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&family=Poppins:wght@600;700;800;900&display=swap');

.DraftEditor-root span {
    background-color: white;
}

@layer base {
    html {
        font-family: Nunito, sans-serif;
        background: #F1F3F7;
    }
}

@layer utilities {
    @variants responsive {
        .text-shadow {
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
        }
        .text-shadow-md {
            text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        }
        .text-shadow-lg {
            text-shadow: 15px 15px 30px rgba(0, 0, 0, 0.1);
        }
        .text-shadow-none {
            text-shadow: none;
        }
    }

    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
}

.swiper {
    overflow: visible !important;
}

.swiper-cards .swiper-slide {
    overflow: visible !important;

    &:not(.swiper-slide-active) {
        opacity: 0;
    }
}

.bg-global {
    background-image: url("../public/images/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100vw;
    max-height: 100vh;
}

.bg-global-inset {
    position: relative;
    overflow: hidden;
}

.bg-global-inset::after {
    @apply absolute;
    @apply inset-0;
    content: "";
    display: block;
    background-image: url("../public/images/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    z-index: -1;
}

.shadow-button {
    box-shadow: 0px 4px 16px rgba(0, 129, 255, 0.18);
}

.shadow-button-red {
    box-shadow: 0px 4px 16px rgba(0, 129, 255, 0.18);
}

.shadow-surface {
    box-shadow: 0px 40px 30px -30px rgba(4, 15, 21, 0.06);
}

.model-list-table.header-disabled {
    thead tr th {
        visibility: hidden;
    }
}

.model-list-table thead tr th {
    @apply px-1;
    @apply sm:px-2;
    @apply lg:px-4;
    @apply py-3;
    @apply sm:py-5;
    @apply font-medium;
    @apply select-none;
    @apply bg-white;

    position: sticky;
    top: 0;
    z-index: 10;

    &::before {
        @apply absolute;
        @apply block;
        @apply inset-0;
        @apply bg-gray-200;
        @apply my-1.5;
        @apply sm:my-3;

        content: "";
        z-index: -1;
    }

    &:first-child::before {
        @apply rounded-tl-lg;
        @apply rounded-bl-lg;
    }

    &:last-child::before {
        @apply rounded-tr-lg;
        @apply rounded-br-lg;
    }
}

.model-list-table tbody tr {
    td {
        @apply h-20;
        @apply sm:h-24;
        @apply px-1;
        @apply sm:px-2;
        @apply lg:px-4;

        position: relative;

        &::before {
            @apply absolute;
            @apply block;
            @apply inset-0;
            @apply transition-all;

            content: "";
            z-index: -1;
        }

        &:first-child::before {
            @apply rounded-tl-lg;
            @apply rounded-bl-lg;
        }

        &:last-child::before {
            @apply rounded-tr-lg;
            @apply rounded-br-lg;
        }
    }

    &:hover td::before {
        @apply bg-gray-50;
    }
}

.bg-grid-texture {
    background-image: url("../public/grid-texture.png");
    background-repeat: repeat;
}

$progress-bar-stroke-width: 1.5;
$progress-bar-size: 8rem;

.progress-circle {
    height: $progress-bar-size;
    transform: rotate(-90deg);
    width: $progress-bar-size;

    .progress-bar__background {
        fill: none;
        stroke: rgba(#006bd2, 0.2);
        stroke-width: $progress-bar-stroke-width;
    }

    .progress-bar__progress {
        fill: none;
        stroke: #fff;
        stroke: #006bd2;
        stroke-dasharray: 100 100;
        stroke-dashoffset: 100;
        stroke-linecap: round;
        stroke-width: $progress-bar-stroke-width;
        transition: stroke-dashoffset 0.1s ease-in-out;
    }
}
